<template>
  <div>
    <b-card title="" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" md="2">
             <b-form-group
                label="No Document"
                label-for="noDocument"
             >
                <b-form-input
                      id="noDocument"
                      size="sm"
                      v-model="form.noDocument"
                      placeholder="No Doc" 
             />
             </b-form-group>
          </b-col>
            <b-col cols="12" md="2">
                <b-button block variant="primary" @click="getData" class="mt-2 pd-1">
                  {{ $t('globalActions.find') }}
                </b-button>
            </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card no-body id="pdfDom">

  <!-- loading -->
    <div class="text-center text-primary my-2 d-flex flex-column justify-content-center"
        v-if="isLoading">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
    </div>

      <b-table
          id="refListTable"
          ref="refListTable"
          no-provider-paging
          no-provider-filtering
          :filter-included-fields="columnToBeFilter"
          :fields="tableColumns"
          :items="itemLists"
          :filter="searchQuery"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :busy="isBusy"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner
} from 'bootstrap-vue'

import { ref, onMounted } from '@vue/composition-api'
import useListTable from '@/comp-functions/useListTable'
import {formatCurrency, formatDatepicker} from '@/utils/formatter'
import useFormResource from '@/comp-functions/useFormResource'



export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner
  },
  setup () {

    const { 
      paramsId
    } = useFormResource({ url: 'transaction/journal', localeContextPath: 'apps.transactionsJournal.singular.transaction', redirectPathName: 'apps-report-journal' })


    const isLoading = ref(false)
     const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

     const tableColumns = [
      { key: 'cn_no', label: 'AWB No' },
      { key: 'awb_origin', label: 'AWB Origin' },
      { key: 'cp_origin', label: 'CP Origin' },
      { key: 'cp_destination', label: 'CP Destination' },
      { key: 'kilo', label: 'Kilo' },
      { key: 'koli', label: 'Koli' },
      { key: 'descp_ch', label: 'CP Code' },
      { key: 'createdby', label: 'Created By' },
      { key: 'harga', label: 'Basic Price 1',
      formatter: value => {
          return formatCurrency(value,false)
        } 
      },
      { key: 'cn_no9', label: 'Basic Price 2' },
      { key: 'cn_no10', label: 'Values' },
      { key: 'cn_no11', label: 'APO Basic' },
      { key: 'cn_no12', label: 'APO Val' },
      { key: 'lokasi_oleh', label: 'Company' },
      { key: 'tanggal_proses', label: 'Cp Date', 
      formatter: value => {
          return formatDatepicker(value,false)
        } 
      },
      { key: 'riwayat', label: 'History' }
    ]

    onMounted(() => {
      if (paramsId) {
        form.value.noDocument = paramsId
        getData()
      }

    })


    // Table Handlers
    const columnToBeFilter = ['code']

    const form = ref({
      noDocument: ''
    })

    const getData = async () => {

        const params = [
        `noDocument=${form.value.noDocument}`
      ]
      isLoading.value = true

      const url = `reports/tracking?${ params.join('&') }`
      await fetchLists(url)
      isLoading.value = false


    }
    return {
      paramsId,
      isLoading,
      form,
      getData,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      sortBy,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      tableColumns,
      columnToBeFilter
    }

  }
}
</script>
